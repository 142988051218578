import React from "react"
import Layout from "../components/layout/layout"
import SEOComponent from "../components/seo"
import AmberScore from "../components/reusable/AmberScore";

const Terms = () => (
  <Layout>
    <SEOComponent title="Amber by inFeedo | Statement of Work" />
    <div class="content has-text-centered columns flex-wrap mar-top-100">
            <h1 class="text-block-17 column is-12 is-paddingless is-size-3">Statement Of Work</h1>
            <h2 class="text-block-17 column is-12 is-paddingless is-size-4">ANNEXURE -1 </h2>
            <h2 class="text-block-17 column is-12 is-paddingless is-size-5">Scope, Product Specifications, Features and other details pertaining to the Software</h2>
            <h2 class="text-block-17 column is-12 is-paddingless is-size-5">Part A: Product Specifications and Features</h2>
            <div className="font-15 pd-40 column is-9 mar-auto has-text-justified">
            <h3 class="text-block-17 column is-12 is-paddingless is-size-6">I. The following shall be the scope of work under the License granted by the Licensor of its Software, “Amber”:</h3>
              <ol>
              <li>Licensor shall provide a non-exclusive, non-transferable license, to use the AI Chat-Bot called <b>‘Amber’</b> on a SAAS (Software as service) model which will be on a per-employee basis.
                </li>
                <li>The Licensee understands and acknowledges that Amber shall be interacting/communicating with employees of the Company as per their life cycle stages (tenure), in the form of a textual chat;
                </li>
                <li>The employee life cycle stages can be customized/added/deleted as per Company’s requirement during the tenure of the contract, which shall be based upon mutual agreement between the Licensor and the Licensee.
                </li>
                <li>The Software will generate surface reports introduced to employees based on demographics like Business Units, Location, Circle, Band, Level, Age on Network, etc. at different points of time. The tool in the Software has the ability to create multiple instances (cohorts) with a customized question based on any one of the demographics.
                </li>
                <li>The Licensor may also share best practices and may also consult with the Company in order to design the questions.
                </li>
                <li>The Software shall provide a real-time dashboard to collate and analyse such information/data collected from the employees of the Company.
                </li>
                <li>The Software shall generate daily and fortnightly reports that shall be sent to the administrators specified by the Company.
                </li>
                <li>The Licensor on a best effort basis shall ensure that the Software’s up time will be 99.95%. The Licensor shall also provide 24*5 tech support globally through its technical help desk – Which can be reached via email in <b>“support@infeedo.com”</b>
                </li>
              </ol>
              <h3 class="text-block-17 column is-12 is-paddingless is-size-6">Features of the Software:</h3>
              <ol>
              <li><b>Employee Lifecycle Stages:</b>These are the points in employee’s tenure in a company when the Software will reach out to them and ask questions over a “smart chat”. Lifecycle stages and the questions Amber asks will be as per the decided and agreed questionnaire, the same may be different for all stages except the Anchor Question.
                </li>
                <li><b>Anchor Question:</b> Every employee lifecycle stage will have one constant question called the Anchor Question. This question is common throughout all stages and is not customizable. The Anchor Question helps the Software do a mood analysis across the entire licensee company and share it in the dashboard.
                </li>
                <li><b>Access Management:</b> Access can be of two types here: Full Access and Restricted Access.
                <ul>
                    <li> Full Access: A user with this access has complete control of the Software and is called super admin and shall have access to all features subscribed to by the Licensee Company.</li>
                    <li> Restricted Access: The company can restrict access to the dashboard in the following ways
                      <ul>
                      <li>Restricted demographic access: In this access, admins can be created by giving them access to restricted demographics like department, location or custom user cohorts. In this access, they can have access to all features of the software</li>
                      <li> Restricted feature access: In this access, admins can be created by giving full demographic access but certain sensitive features can be restricted</li>
                      <li>Restricted demographic and feature access: In this access, admins can be created by restricting both demographics and features on the software.</li>
                      </ul>
                    </li>
                    <li>Hierarchy Access: Company can decide to provide access to business leaders or even managers basis their hierarchy. Further to their hierarchy, for these accesses, some features can be toggled off considering the sensitivity of data.</li>
                </ul>
                </li>
                <li><b>Smart Chat:</b> Using Natural Language Processing (as detailed below) the Software is able to empathize with the person who is chatting in the form of textual responses and ask to follow up questions accordingly.
                </li>
                <li><b>Anonymous Bat:</b> In addition to the smart chat with the Software, employees also have an option to chat with “Anonymous Bat” where all messages they send are completely anonymous from their end. 
                </li>
                <li><b>Natural Language Processing:</b>Natural language processing (“<b>NLP</b>”) is a field of computer science, artificial intelligence, and computational linguistics concerned with the interactions between computers and human (natural) languages and, in particular, concerned with programming computers to fruitfully process large natural language corpora.
                </li>
                <li><b>Dashboard:</b> Amber’s dashboard has 6 mini-features built into it:
                <ul>
                    <li>People to Meet: People who are detected as unhappy by our algorithm and NLP are shown in this section. Idea is to prioritize meeting those people over the others to resolve such issues and avoid termination and/or resignation due to such issues in Licensee Company.</li>
                    <li> Anonymous Messages: All messages sent through Anonymous Bat are shown in this section of the Dashboard.</li>
                    <li> Stages Analysed: This section shows the analysis of each lifecycle stage.</li>
                    <li> Themes: Find out common trends across each department, location, function, age group etc.</li>
                    <li> Filters: Filters provided in Software allows admins to slice and dice the dashboard in various ways allowing them the flexibility to choose what to see.</li>
                    <li> Engagement Score: This is a summarized score of complete chat and provides a holistic view of the sentiment of complete chat.</li>
                    <li> TextSens: A proprietary text analytics module developed by our AI team to help you analyse incoming responses from chats.</li>
                    <li> Saved views: User can create and save views on dashboard. They can also choose which view should be their default view every time they log in to dashboard.</li>
                    <li>Themes: Find out common trends across each department, location, function, age group etc. Analytics to find out under which demography which theme is not working well.</li>
                </ul>
                </li>
                <li><b>Amber’s Emails to Employees:</b>  Amber sends the following 2 emails to employees:
                <ul>
                    <li>Lifecycle Email: The lifecycle email is the one in which users are asked to share their feedback and this can be accessed by clicking on Amber’s chat URL link which will be provided in the said email. This email’s content is entirely customisable. It usually goes in the name of the company’s CEO (otherwise CHRO/HR Head).</li>
                    <li>Reminder Emails: To ensure maximum participation, 4 auto-reminders are sent in a span of 30 days.</li>
                   </ul>
                </li>
                <li><b>Amber’s Emails to Admins:</b> Amber sends out the following emails to admins:
                <ul>
                    <li> Fortnightly Report: On 1st and 16th of each month all admins receive an email giving a summary of what all happened in the preceding 15 days.</li>
                    <li> Daily Report: An email also goes out daily with the list of employees who chatted with Amber the day before. Admins can choose to unsubscribe from these emails too.</li>
                    <li> Schedule 1:1/Follow Up: Admins have an option to initiate an email conversation with people from the dashboard itself.</li>
                   </ul>
                </li>
                <li><b>Cohorts:</b> Cohort is a method where the client can ask different sets of questions, to different audiences based on employee grade, business units and so on.
                </li>
                <li><b>Acknowledgement Email (Optional Feature):</b> Once an admin reads an employee's chat, an acknowledgement email will go out to that particular employee. These emails will be sent out based on the overall sentiment of the chat i.e. if it is a positive sentiment then they will receive a positive acknowledgement if there's a negative sentiment, then an email telling them that action will be taken if required will be sent. (Exact email content can be shared with you).
                </li>
                <li><b>SMS Reminders (Optional Feature):</b> Reach-outs and reminders in India will reach employees on their mobile phones and they will be able to reach Amber's chat via a link in the message. As it is an add- on feature, it can be implemented at a small additional cost.
                </li>
              </ol>
            </div>
            <h2 class="text-block-17 column is-12 is-paddingless is-size-5">Part B: Ancillary Services</h2>
            <div className="font-15 pd-40 column is-9 mar-auto has-text-justified">
              <ol>
                <li><b>Corrective Maintenance:</b>
                <ul>
                    <li>Maintenance of the Software shall comprise diagnosis and correction by the Licensor of actual errors or defects in the program codes of the components of the Software and/ or the provision of patches, bug fixes and workarounds (“Corrective Maintenance”) to errors reported by the Licensee Company. The tables set out below reflects Licensor’s response and resolution times.</li>
                    <li> The Licensee Company shall report errors and defects to, the Customer Satisfaction Team via emailing the same to <a href="mailto:support@infeedo.com">support@infeedo.com.</a></li>
                    <li> Licensor shall provide Corrective Maintenance in accordance with the timescales and priorities set out in the tables below. Response and resolutions times are measured from the time the Licensee Company reports the error or defect to Licensor.
                    <br></br>
                    <br></br>
                    <h3 className="is-size-5 is-size-6-mobile"><b>Table I: Priority Class, Response Time, Resolution Time and Service Credits</b></h3>
                      <table class="table is-bordered">
                        <tr>
                          <th>Priority</th>
                          <th>Response Time</th>
                          <th>Patch Fix</th>
                          <th>Resolution Time</th>
                        </tr>
                        <tr>
                          <td>Critical</td>
                          <td>1 Business hour</td>
                          <td>Within 8 hours</td>
                          <td>Resolution time shall be mutually accepted by the Parties after the effort assessment by the Licensor’s Engineering team.</td>
                        </tr>
                        <tr>
                          <td>High</td>
                          <td>4 Business hours</td>
                          <td>Within 24 Business hours</td>
                          <td>Resolution time shall be mutually accepted by the Parties after the effort assessment by the Licensor’s Engineering team.</td>
                        </tr>
                        <tr>
                          <td>Medium</td>
                          <td>24 Business hours</td>
                          <td>NA</td>
                          <td>7 days</td>
                        </tr>
                      </table>
                      <p className="is-size-7"> Patch Fix: A temporary solution provided by the Licensor in order for the Licensee to continue the use of the software till the actual resolution time is determined by the Parties as per Table I above.</p>
                      <br></br>
                      <h3 className="is-size-5 is-size-6-mobile"><b>Table II: Priority Class Description/Classification</b></h3>
                      <table class="table is-bordered">
                        <thead>
                          <tr>
                            <th class="no-wrap">Priority Class</th>
                            <th >Description</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td><b>Critical</b></td>
                            <td>The software has stopped responding or is so severely impacted that use of the Platform cannot continue. The error has one or more of the following characteristics:
                              <ul>
                                <li>Data corrupted.</li>
                                <li>A critical documented function is not available.</li>
                                <li>System hangs indefinitely, causing unacceptable or indefinite delays for resources or response. Subject to the following cases but not limited to – a) Chat reach outs not happening or are incorrect or chat link is inaccessible; b) Dashboard is not opening or is hung. </li>
                              </ul>
                              <p>These cases are only applicable where causality is in the Licensor’s control.</p>
                            </td>
                          </tr>
                          <tr>
                            <td><b>High</b></td>
                            <td> 
                            <p><u>Partial loss of service.</u> A high-impact problem where the Platform is usable, but in a significantly impaired fashion; there is a time-sensitive issue important to long term use of the Software that is causing significant customer concern.</p>
                            <p>Important functions are unavailable with no workaround acceptable to the Buyer; however, operation of the Software by a user can continue on a restricted basis.</p>
                            <p>Example – a) Export feature has shut down,; or b) a particular page is not accessible on the dashboard; or c) non-deliveries of automated reports.</p>
                            </td>
                          </tr>
                          <tr>
                            <td><b>Medium</b></td>
                            <td>
                              <p> <u>Minor loss of service.</u> The impact is an inconvenience, which may require a workaround to restore that particular functionality</p>
                              <p>Operation of the Software by a user is not adversely affected.</p>
                              <p>Examples include – the inability of the Licensee to export a particular column from the dashboard.</p>
                            </td>
                          </tr>
                          <tr>
                            <td><b>Low</b></td>
                            <td>
                            <p><u>A minor issue causing an inconvenience.</u> There is no loss of service. The impact does not impede the operation of Software</p>
                            <p>Examples include: minor spelling errors.</p>
                            </td>
                          </tr>
                        </tbody>
                        </table>
                        <p>All examples stated hereinabove are just indicative and not exhaustive</p>
                        </li>
                        </ul>
                        <br></br> 
                        <li> InFeedo/Licensor warrants that it will perform all Corrective Maintenance using suitably qualified personnel, experienced with use of the Software and provision of the Services, using all due care and skill in conformity with good professional practice.</li>
                        <li><b> “Helpdesk”:</b> : InFeedo/Licensor shall provide the facility of a Helpdesk between 10.00 am to 7.00pm, Monday to Friday, India Local Time on all Business Days.</li>
                        <li>Any terms not defined herein in this Annexure has been defined in the agreement/terms and conditions as agreed to between Parties hereto.</li>
                    </li>
                  </ol>
            </div>
    </div>
    <AmberScore/>
  </Layout>
)

export default Terms


